<template>
  <!-- 量表列表 -->
  <div class="gauge">
    <div class="gaugeContent">
      <div class="item" v-for="item in gaugeList" :key="item.id" @click="toIntroduce(item)">
        <div class="name">
          <div class="box1"></div>
          <div class="box2"></div>
          <p class="p2">{{ item.name }}
            <img src="../../../assets/images/index/gaugeLogo.png" alt="">
          </p>
        </div>
        <div class="text">
          <div class="memo">
            <span class="span2 memoText">
              {{ item.memo }}</span>
            <!-- <div class="btn">
                  <span class="span2">
                    开始测试
                  </span>
                </div> -->
          </div>


        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange"
            :current-page.sync="paging.pageNumber" :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
    </div>
  </div>
</template>

<script>
import { getGaugeList, getGaugeList2} from '@/api/gauge'
export default {
  data() {
    return {
      currentPage3: 1,
      gaugeList:[],
      paging: {
        pageNumber: 1,
        pageSize: 8,
        total: 0,
      },
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.paging.pageNumber = val;
      this.getGaugeData();
      // console.log(`当前页: ${val}`);
    },
    // 获取量表列表
    // async getGaugeData() {
    //   let info = this.$store.state.gaugeListInfo;
    //   info.id = JSON.parse(sessionStorage.getItem('userInfo')).id
      
    //   // let search = {pageNumber: this.paging.pageNumber,
    //   //   pageSize: this.paging.pageSize,...info}
    //   const res = await getGaugeList2(info);
    //   console.log(res);
    //   if(res.code === 200) {
    //     this.gaugeList =(res.data).slice(0,8);
    //     // this.paging.total = res.data.total;
    //     // console.log(this.gaugeList)
    //   }
    // },
    async getGaugeData() {
      let info = this.$store.state.gaugeListInfo;
      info.id = JSON.parse(sessionStorage.getItem('userInfo')).id
      
      let search = {pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize,...info}
      const res = await getGaugeList(search);
      console.log(res);
      if(res.code === 200) {
        this.gaugeList =res.data.records;
        this.paging.total = res.data.total;
        // console.log(this.gaugeList)
      }
    },
    // 跳转到量表介绍
    toIntroduce(val) {
      let type = 1;
      if(val.completeProblem > 0 && val.completeProblem < val.problemCount){
        type = 2;
      }
      this.$router.push({
        path: '/test/evaluation/introduce',
        query: {
          gaugeId: val.id,
          isRandom: val.isRandom,
          type: type
        }
      })
    }
  },
  created() {
    this.getGaugeData();
  }
}
</script>

<style lang="scss" scoped>
.gauge {
  padding-top: 40px;
  // height: 100%;
  .gaugeContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    .item {
      width: 48%;
      // width: 527px;
      box-sizing: border-box;
      margin-bottom: 20px;

      .name {
        // height: 39px;
        padding: 9px 0;
        width: 100%;
        // line-height: 39px;
        background: var(--listStyle-color);
        position: relative;
        border-right: 1px solid var(--listStyle-color);
        box-sizing: border-box;
        border-left: 0px;

        p {
          padding-left: 20px;
          font-weight: 600;
          color: var(--headFont-color);
          box-sizing: border-box;

          img {
            position: absolute;
            right: 20px;
            // top:0;
            height: 25px;
          }

          // line-height: 39px;
        }

        .box1 {
          position: absolute;
          left: -12px;
          top: 0px;
          width: 0;
          height: 0;
          border-color: transparent transparent var(--listStyle-color) transparent;
          border-style: solid;
          border-width: 0 0 38px 12px;
        }

        .box2 {
          position: absolute;
          right: 0px;
          top: 0px;
          width: 0;
          height: 0;
          border-color: transparent transparent white transparent;
          border-style: solid;
          border-width: 0 0 38px 12px;
        }

      }

      .text {
        // margin-top: 20px;
        border: 1px solid var(--listStyle-color);
        border-top: none;

        .memo {
          padding: 13px 0px;
          padding-left: 20px;
          padding-right: 10px;
        }

        .memoText {
          margin-bottom: 13px;
          color: var(--memoFont-color);
          // line-height: 22px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        // span {
        //   // display: inline-block;
        //   // padding: 13px 0px;

        // }


      }

      &:hover {
        cursor: pointer;

        .name {
          background: var(--custom-color);
          border-right: 1px solid var(--custom-color);

          .box1 {
            border-color: transparent transparent var(--custom-color) transparent;
          }
        }

        .text {
          border: 1px solid var(--custom-color);
          border-top: 0px;
        }


      }
    }
  }
}
@media screen and (max-width: 600px) {
  .gauge {
    .gaugeContent {
    .item{
      width: 527px;
    }
  }
  }
  
}
</style>